import * as React from 'react'
import ProjectCard from '../components/ProjectCard'

const components = {
  ProjectCard: ({ link, title, bg, children, ...props }) => (
    <ProjectCard {...props} link={link} title={title} bg={bg}>
      {children}
    </ProjectCard>
  )
}

export default components
