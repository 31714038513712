/** @jsx jsx */
import { jsx } from 'theme-ui'

const ProjectCard = ({ link, imgLink, centerImg, title, date, children, bg }) => {
  const baseURL = 'https://res.cloudinary.com/arun99-dev/image/upload/v1626528475/arun99.dev'

  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer noopener'
      sx={{
        width: '100%',
        boxShadow: 'lg',
        position: 'relative',
        textDecoration: 'none',
        borderRadius: 'lg',
        px: 4,
        py: [4, 5],
        color: 'white',
        background: bg || 'none',
        transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important',
        '&:hover': {
          color: 'white !important',
          transform: 'translateY(-5px)',
          boxShadow: 'xl'
        }
      }}
    >
      <div sx={{ opacity: 0.85, textShadow: '0 2px 10px rgba(0, 0, 0, 0.3)' }}>{children}</div>
      <div
        sx={{
          borderRadius: 'lg',
          backgroundImage: `url(${baseURL}${imgLink})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
          backgroundPosition: centerImg ? 'center center' : 'center top',
          width: '100%',
          height: '150px',
          objectFit: 'cover'
        }}
      />
      <div
        sx={{
          textTransform: 'uppercase',
          letterSpacing: 'wide',
          pt: 3,
          fontSize: [4, 5],
          fontWeight: 'medium',
          lineHeight: 1
        }}
      >
        {title}
      </div>
      <div
        sx={{
          letterSpacing: 'wide',
          pt: 1,
          fontSize: 1,
          fontWeight: '400',
          lineHeight: 1.2
        }}
      >
        {date}
      </div>
      {/* <div
        sx={{
          letterSpacing: 'wide',
          pt: 1,
          fontSize: [1, 2],
          fontWeight: '400',
          lineHeight: 1.2,
        }}
      >
        Description
      </div> */}
    </a>
  )
}

export default ProjectCard
